// @import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
//global colors for rebranding
@import "./gobal-colors.scss";
// Custom Theming for Angular Material

// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import "@angular/material/theming";
@import "./typography.scss";
@import "./theme/material.scss";
@import "./theme/query-builder.scss";
@import "./helper.scss";
@import "./custom.scss";

/* You can add global styles to this file, and also import other style files */

//variables
$scrollbar-track-color: #ffffff00;
$scrollbar-track-hover-color: #ffffff;
$scrollbar-thumb-color: #5cb1da;
//Common Dialog
$common-dialog-shadow-color: #00000061;

html,
body {
  height: 100%;
  font-family: "Open-Sans-Regular";
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  // @extend .typo-base;
  @include scrollbars(7px, 30px, $scrollbar-thumb-color, $scrollbar-track-color);

  ::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
    border-radius: 30px;
    transition: 0.3s;
  }

  ::-webkit-scrollbar-track:hover {
    background: $scrollbar-track-hover-color;
    transition: 0.3s;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: #5cb1da;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box; // <== make the border work
  }

  ::-webkit-scrollbar-thumb:hover {
    border: 0;
  }

  // Global CSS for Toolbar
  .primary-toolbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--secondary-color); //rebranding changes
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;

    .toolbar-title {
      font-size: 18px;
      background-color: transparent;
      color: var(--primary-color); //rebranding changes
    }

    .toolbar-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button,
      span,
      div {
        margin-left: 5px;
      }
    }
  }

  // Global CSS for notification side-panel container for rebranding
  .sidebar-header-title {
    color: var(--primary-color); //rebranding changes
    padding-left: 10px;
  }
}

.sidebar-container-detail {
  background-color: var(--secondary-color); //rebranding changes
  color: var(--primary-color);
  margin-left: -7px;
  padding: 15px 1.5em;
}

// Global CSS for attachment in record details - for rebranding
.sidebar-attachments {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  .attachment {
    padding: 0 0 0 20px;
    margin-bottom: 10px;

    div {
      border: 1px solid var(--secondary-color); //rebranding changes
      border-radius: 5px;
      cursor: pointer;
      padding: 5px 10px;
    }

    label {
      cursor: pointer;
    }
  }
}

// Global CSS for documents in record-details
.question-attachments {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 0 0 0 20px;
  margin-bottom: 10px;
  border: 1px solid var(--secondary-color); //rebranding changes
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

// anchor css
a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.form-label {
  font-size: 12px;
  font-family: "OpenSans_Bold";
}

.questionnaire-icon {
  display: inline-flex;
  position: absolute;
  margin-top: -1px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}